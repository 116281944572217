#App {
    max-width: var(--page-width);
    margin: auto;
    font-size: 20px;
}
.App-title {
    text-transform: uppercase;
    font-size: 70px;
    margin-bottom: 0;
}
.App-title-hr {
    color: inherit;
    border: 4px solid currentColor;
    max-width: 300px;
    margin: 1rem 0;
}

.App-footer-hr {
    color: inherit;
    border: 1px solid currentColor;
    max-width: 300px;
    margin: 2rem 0 0;
}
.App-footer {
    font-size: 16px;
}
